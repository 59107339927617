<script>
// Extensions
import View from '@/views/View'

// Mixins
import LoadSections from '@/mixins/load-sections'

export default {
   name: 'Dashboard',

   metaInfo: { title: 'Dashboard' },

   extends: View,

   mixins: [
      LoadSections([
         'page-dashboard',
      ]),
   ],

   props: {
      id: {
         type: String,
         default: 'dashboard',
      },
   },
}
</script>
